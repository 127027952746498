<template>
  <div>
    <h1 :class="$style.pageTitle">支店作成</h1>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form-branch />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormBranch from '../../../components/FormComponents/Branch'

export default {
  components: {
    FormBranch,
  },
}
</script>
<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
</style>
